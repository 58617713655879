let DataService = {};
DataService.listStatus = [
  {
    label: "Khóa",
    value: 'true',
  },
  {
    label: "Hoạt động",
    value: 'false',
  },
];

export default DataService;