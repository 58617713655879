import ValidService from "../../service/ValidService";

let model = {};

// dữ liệu form
model.dataForm = {
  id: undefined, // optional - Mã tự sinh
  type: undefined, // required, enum(probe, bras) - Phân loại probe/bras
  disabled: undefined, // required - Cho biết dịch vụ có bị disable hay không
  index: undefined, // required - Thứ tự hiển thị
  name: undefined, // required, minLength: 1 - Tên dịch vụ
  fullname: undefined, // required, minLength: 1 - Tên đầy đủ
  avatar_id: undefined, // optional, ref: mushroom_file - Ảnh đại diện của dịch vụ
  is_menu_hidden: undefined, // optional - Có ẩn trên menu không?
};

(model.validForm = {
  name: [
    ValidService.required,
    ValidService.checkNameAllowVN,
    ValidService.checkSpaceString,
    ValidService.checkMaxLength(50),
  ],
  fullname: [
    ValidService.required,
    ValidService.checkNameAllowVN,
    ValidService.checkSpaceString,
    ValidService.checkMaxLength(50),
  ],
  disabled: [ValidService.requiredChange],
  index: [
    ValidService.required,
    ValidService.checkNumberPositive,
    ValidService.checkNumberFloatNotNegative,
  ],
  type: [ValidService.required],
}),
  (model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: true,
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sort: "index",
    defaultSort: ["index", "ascending"],
    fields: "id,name,fullname,disabled,index,is_menu_hidden",
    filters: "",
    dataSearch: {
      value: {
        name: "",
        fullname: "",
        disabled: "",
        type: "",
        index: "",
      },
      valid: {
        name: [ValidService.checkNameAllowVN],
        fullname: [ValidService.checkNameAllowVN],
        disabled: [],
        is_menu_hidden: [],
        index: [],
      },
      operator: {
        name: ":regex_i:",
        fullname: ":regex_i:",
        disabled: "",
        type: "",
        index: "",
      },
    },
  });

export default model;
